import React from 'react'

import Img, { getImageUrl, Sizes } from '../components/blocks/editable/Img'

import { Helmet } from "react-helmet";

import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js/pure';

import Div from '../components/layouts/Div'

import LocationInput from '../components/blocks/editable/LocationInput'

import PaymentDetails from '../components/PaymentDetails'

import General from '../utils/General'
import Backend from '../utils/Backend'
import ScriptCache from '../utils/ScriptCache'
import Cart from '../utils/Cart'
import Currency from '../utils/Currency'
import Notify from '../utils/Notify'

loadStripe.setLoadParameters({advancedFraudSignals: false});
let stripePromise = null

let CARD_ELEMENT_STYLE = {
  base: {
    iconColor: '#666666',
    color: '#fff',
    fontSize: '16px',
    '::placeholder': {
      color: '#87BBFD',
    },
  },
  invalid: {
    iconColor: '#FFC7EE',
    color: '#FFC7EE',
  },
}

export default class Checkout extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      checkingOut: false,
      cart: props.cart,
      location: {},
      website: props.website,
      shop: props.shop,
      error: null,
    }

    this.paymentDetails = React.createRef()
  }

  componentDidMount() {
    this._setup()
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      ...nextProps,
      error: null
    }, () => this._checkCoupon())
  }

  _setup(){
    let {
      shop,
      website,
    } = this.state


    stripePromise = loadStripe(window.Api.StripePubishableKey, {
      stripeAccount: shop.stripe_account_id
    })

    console.log("dddd", shop.stripe_account_id)

    let shipping_method = null
    if(shop.shipping_enabled && (shop.shipping_methods === "delivery" || shop.shipping_methods === "collection")){
      shipping_method = shop.shipping_methods
    }

    this.setState({
      shop,
      shipping_method,
      loading: false,
      loadingShop: false,
    }, () => this._loadJs())
  }

  _loadJs(){
    ScriptCache.loadDefaults()
    setTimeout(() => {
      General.updateAll()
    }, 300)
  }

  _getShippingPrice(){
    let {
      shop,
      cart,
      shipping_method
    } = this.state

    let shippingPrice = 0
    if(shop.shipping_fees_type === "none"){
      return shippingPrice
    }

    if(shipping_method === "delivery" && shop.shipping_fees_type !== "none"){
      if(shop.shipping_free_threshold && cart.subtotal >= shop.shipping_free_threshold){
        shippingPrice = 0
      }
      else{
        shippingPrice = cart.shipping_price
      }
    }

    return shippingPrice
  }

  _isFormValid(){
    let {
      first_name,
      last_name,
      location,
      email,
      phone,
      shipping_method,
      cart,
      shop
    } = this.state

    let error = null
    if(!cart || cart.items.length === 0){
      error = "Your cart is currently empty"
    }
    else if(!first_name){
      error = "Please enter your first name"
    }
    else if(!last_name){
      error = "Please enter your last name"
    }
    else if(!email){
      error = "Please enter your email"
    }
    else if(!phone){
      error = "Please enter your phone number"
    }
    else if(shop.shipping_enabled && shipping_method == null){
      error = "Please select a delivery method"
    }
    else if(!location || !location.postal_code){
      error = "Please enter a more specific address"
    }
    else if(!location.city || !location.state || !location.country || !location.postal_code){
      error = "Please enter a more specific address"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _handleCheckout(){
    if(!this._isFormValid()){
      return null
    }

    this._checkout()
  }

  _checkCoupon(){
    let {
      couponCode,
      website,
      cart
    } = this.state

    if(couponCode){
      if(couponCode.length === 5){
        Backend.getCoupon(couponCode, website).then(coupon => {
          this.setState({coupon, couponFailed: false, couponSucceeded: true}, () => {
            Cart.addCoupon(coupon).then(() => {
              this.setState({ cart });
            }).catch((error) => {
              Notify.error(error.message);
            });
          })
        }).catch(e => {
          this.setState({
            couponFailed: true, couponSucceeded: false, coupon: null
          })
        })
      }
    }
  }

  _checkout(){
    let {
      cart,
      shop,
      coupon,
      location,
      first_name,
      last_name,
      email,
      phone,
      shipping_method,
      paymentIntentId
    } = this.state

    let subtotal = cart.subtotal
    let shippingPrice = this._getShippingPrice()
    let couponValue = 0

    let data = {
      location,
      first_name,
      last_name,
      email,
      phone,
      shipping_method,
      ...cart
    }

    if(coupon){
      let validCoupon = Cart.isCouponValid(subtotal, coupon)
      if(validCoupon){
        if(coupon.type === "free_shipping"){
          shippingPrice = 0
        }else{
          let appliedCoupon = Cart.applyCoupon(subtotal, coupon, shop.currency)
          couponValue = appliedCoupon.value
        }
        data.voucher = coupon.id
      }
    }

    data.expected_price = (subtotal - couponValue) + shippingPrice
    data.expected_price = data.expected_price < 0 ? 0 : data.expected_price

    let customer = {
      first_name,
      last_name,
      email,
      phone,
      billing_address: location
    }

    this.setState({ checkingOut: true, error: null })
    this.paymentDetails.current.getPaymentDetails(data.expected_price, customer)
    .then(paymentDetails => {
      data.payment_details = paymentDetails
      return Backend.checkout(data)
    })
    .then(response => {
      if(response.requires_action){
        this.paymentDetails.current.validatePayment(response, data.expected_price, shop.currency)
        .then(() => this._handleCheckout())
        return
      }

      this.paymentDetails.current.clearForm()

      this._onSale(response)
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ checkingOut: false })
    })
  }

  _onSale(sale){
    Notify.success("Your order has been placed")
    this.setState({
      sale,
      checkingOut: false
    }, () => {
      this.props.onCheckedOut()
    })
  }


  _handleChange(e){
    let state = this.state
    state[e.target.name] = e.target.value
    state.error = null
    this.setState(state)
  }

  _renderCartItems(cart){
    let {
      shop
    } = this.state
    return cart.items.map((item, index) => {
      let price = item.product.on_sale ? item.product.sale_price : item.product.price
      let stock = item.product.stock
      let variant;
      if(item.variant){
        variant = item.variant.name || item.variant.options.join(', ')
        price = item.variant.price
        stock = item.variant.stock
      }
      return (
          <div class="product-item">
            <div class="d-flex align-items-center">
              <div class="img m-0" style ={{ backgroundImage: "url("+getImageUrl(item.product.featured_image, Sizes.SMALL)+")" }} />
              <a
                class="m-0 h5 col title"
                href={`/shop/product/${item.product.slug}`}
              >
                {item.product.name}
                <span className="variant">{variant}</span>
              </a>
            </div>
            <div class="d-flex align-items-center mt-2">
              <div class="input-number col-auto p-0">
                  <input
                    type="number"
                    name="quantity"
                    placeholder="Quantity"
                    value={item.quantity}
                    min="1"
                    max={stock}
                  />
                  <div class="input-number__controls">
                      <span
                        class="input-number__increase"
                        onClick={() => this.props.onSetCartItemPressed(item.product, item.variant, item.quantity + 1)}
                      >
                        <i class="stack-up-open"/>
                      </span>
                      <span
                        class="input-number__decrease"
                        onClick={() => this.props.onSetCartItemPressed(item.product, item.variant, item.quantity - 1)}
                      >
                        <i class="stack-down-open"/>
                      </span>
                  </div>
              </div>
              <div class="col-2 p-0 delete text-center">
                <a
                  href="javascript:void(0);"
                  class="material-icons"
                  onClick={() => this.props.onRemoveFromCartPressed(item.product, item.variant, item.quantity)}
                >
                  delete
                </a>
              </div>
              <span class="col p-0 text-right price">{ Currency.format(price * item.quantity, shop.currency) }</span>
            </div>
          </div>
      )
    })
  }

  _renderCart(cart){
    let {
      shop,
      coupon,
      couponFailed,
      couponSucceeded,
      shipping_method
    } = this.state

    let subtotal = cart.subtotal
    let shippingPrice = this._getShippingPrice()

    let couponText = ''
    let couponValue = 0
    if(coupon){
      let validCoupon = Cart.isCouponValid(subtotal, coupon)
      if(validCoupon) {
        if (coupon.type === "free_shipping") {
          shippingPrice = 0
          couponText = 'FREE SHIPPING'
        } else {
          let appliedCoupon = Cart.applyCoupon(subtotal, coupon, shop.currency)
          couponValue = appliedCoupon.value
          couponText = appliedCoupon.couponText
        }
      }else{
        coupon = null
        couponFailed = true
        couponSucceeded = false
      }
    }

    let total = (subtotal - couponValue) + shippingPrice
    total = total < 0 ? 0 : total

    return (
      <div key={cart.items.length} class="col-md-6 col-left">
          <div class="row">
              <div class="col-md-12">
                  <div class="boxed boxed--border cart-total m-0">

                  <h4>Cart &amp; Checkout</h4>

                  { this._renderCartItems(cart) }

                  <hr />

                  <div class="row m-md-b-0">
                      <div class="col-6">
                          <span class="h5">Subtotal:</span>
                      </div>
                      <div class="col-6 text-right">
                          <span>{ Currency.format(subtotal, shop.currency) }</span>
                      </div>
                  </div>
                  <div class="row m-md-b-0">
                      <div class="col-6">
                          <span class="h5">Shipping:</span>
                      </div>
                      <div class="col-6 text-right">
                          { shippingPrice > 0 &&
                          <span>{ Currency.format(shippingPrice, shop.currency) }</span>
                          }
                          { (shippingPrice === 0 && shipping_method === "delivery") &&
                            <span>FREE</span>
                          }
                          { (shippingPrice === 0 && shipping_method === "collection") &&
                            <span>Collection</span>
                          }
                      </div>
                  </div>
                    {
                      coupon &&
                      <div className="row m-md-b-0">
                        <div className="col-6">
                          <span className="h5 coupon-applied">Coupon:</span>
                        </div>
                        <div className="col-6 text-right coupon-applied">
                          {
                            couponText
                          }
                        </div>
                      </div>
                    }
                  <hr />
                  <div class="row m-md-b-0">
                      <div class="col-6">
                          <span class="h5">Total:</span>
                      </div>
                      <div class="col-6 text-right">
                          <span class="h5">{ Currency.format(total, shop.currency) }</span>
                      </div>
                  </div>
                    <hr />
                    <div className="row m-md-b-0">
                      <div className="col-8">
                        <span className="h5">Coupon:</span>
                      </div>
                      <div className="col-4 text-right">
                        <input
                          type="text"
                          name="code"
                          maxLength={5}
                          className={`form-control form-control-solid`}
                          onChange={e => {
                            let couponCode = e.target.value
                            this.setState({couponCode}, () => this._checkCoupon())
                          }}
                        />
                      </div>
                      {
                        couponFailed &&
                        <>
                          <div className="col-8"/>
                          <div className="col-4 text-right">
                            <p className="Mui-error">Invalid Coupon!</p>
                          </div>
                        </>
                      }

                      {
                        couponSucceeded &&
                        <>
                          <div className="col-8"/>
                          <div className="col-4 text-right">
                            <p className="coupon-valid">Coupon Applied!</p>
                          </div>
                        </>
                      }

                    </div>
                  </div>
              </div>
          </div>
      </div>
    )
  }

  _renderSaleItems(sale){
    let {
      shop
    } = this.state

    return sale.data.map((item, index) => {
      let price = item.product.on_sale ? item.product.sale_price : item.product.price
      let name = `${item.quantity}x ${item.product.name}`
      if(item.variant){
        name += ` (${item.variant.name || item.variant.options.join(', ')})`
        price = item.variant.price
      }

      return (
        <div class="d-flex align-items-center product-item">
            <Img class="m-0" img={item.product.featured_image} size={Sizes.SMALL} style={{ width: 50 }}/>
            <a
              class="m-0 h5 col"
              href={`/shop/product/${item.product.slug}`}
            >
              { name }
            </a>
            <span class="col-2 p-0 text-right price">{ Currency.format(price * item.quantity, shop.currency) }</span>
        </div>
      )
    })
  }

  _renderSale(sale){
    let voucher = sale.voucher

    let subtotal = sale.data.reduce((prevVal, item) => {
      let itemPrice = item.product.on_sale ? item.product.sale_price : item.product.price
      if(item.variant){
        itemPrice = item.variant.price
      }
      return prevVal + itemPrice
    }, 0);

    let couponText = ""
    let appliedCoupon = null

    if(voucher){
      if (voucher.type === "free_shipping") {
        couponText = 'FREE SHIPPING'
      } else {
        appliedCoupon = Cart.applyCoupon(subtotal, voucher, this.state.shop.currency)
        couponText = appliedCoupon.couponText
      }
    }

    let orderNumber = sale.id ? `#${sale.id}` : ""
    let totalPrice = Currency.format(sale.amount, sale.currency)
    if(!sale.id && appliedCoupon){
      totalPrice = Currency.format(sale.amount - appliedCoupon.value, sale.currency)
    }
    return (
      <div class="col-md-6 col-mid">
          <h4 class="mb-0 text-center">{`Thank You For Your Order`}</h4>
          <p class="mb-0 text-center">{`A confirmation email has been sent to you.`}</p>
          <p class="mb-0 text-center">{`Order ${orderNumber}`}</p>
          <div class="row">
              <div class="col-md-12">
                  <div class="boxed boxed--border cart-total m-0">

                  <h4>{`Order ${orderNumber}`}</h4>

                  { this._renderSaleItems(sale) }

                  <hr />

                  <div class="row m-md-b-0">
                      <div class="col-6">
                          <span class="h5">Subtotal:</span>
                      </div>
                      <div class="col-6 text-right">
                          <span>{ Currency.format(subtotal, sale.currency) }</span>
                      </div>
                  </div>
                  <div class="row m-md-b-0">
                      <div class="col-6">
                          <span class="h5">Shipping:</span>
                      </div>
                      <div class="col-6 text-right">
                          <span>{ Currency.format(sale.shipping_price, sale.currency) }</span>
                      </div>
                  </div>

                  {
                    voucher &&
                    <div className="row m-md-b-0">
                      <div className="col-6">
                        <span className="h5 coupon-applied">Coupon:</span>
                      </div>
                      <div className="col-6 coupon-applied text-right">
                        <span>{couponText}</span>
                      </div>
                    </div>
                  }

                  <hr />
                  <div class="row m-md-b-0">
                      <div class="col-6">
                          <span class="h5">Total:</span>
                      </div>
                      <div class="col-6 text-right">
                          <span class="h5">{ totalPrice }</span>
                      </div>
                  </div>
                  </div>
              </div>
          </div>
          <a
            class="btn btn--primary w-100 mt-5"
            onClick={e => {
              window.location.href = "/shop"
            }}
          >
            <span class="btn__text">
              Continue Shopping
            </span>
          </a>
          <a
            class="btn btn--secondary w-100 mt-4 ml-0"
            onClick={e => {
              window.location.href = "/"
            }}
          >
            <span class="btn__text">
              Back To Site
            </span>
          </a>
      </div>
    )
  }



  _renderContent(){
    let {
      cart,
      sale,
      error,
      coupon,
      first_name,
      last_name,
      email,
      phone,

      location,

      shipping_method,
      shop
    } = this.state

    if(sale){
      return this._renderSale(sale)
    }

    let subtotal = cart.subtotal
    let shippingPrice = this._getShippingPrice()
    let couponValue = 0

    if(coupon){
      let validCoupon = Cart.isCouponValid(subtotal, coupon)
      if(validCoupon) {
        if (coupon.type === "free_shipping") {
          shippingPrice = 0
        } else {
          let appliedCoupon = Cart.applyCoupon(subtotal, coupon, shop.currency)
          couponValue = appliedCoupon.value
        }
      }
    }

    let total = (subtotal - couponValue) + shippingPrice
    total = total < 0 ? 0 : total

    return (
      <>
        { this._renderCart(cart) }

        <div class="col-md-6 col-right">

            <form class="">

                <div class="row">

                    <div class="col-md-6">
                      <div class="input-icon">
                          <i class="material-icons">person</i>
                          <input
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            value={first_name}
                            onChange={e => this._handleChange(e)}
                          />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="input-icon">
                          <i class="material-icons">person</i>
                          <input
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            value={last_name}
                            onChange={e => this._handleChange(e)}
                          />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="input-icon">
                          <i class="material-icons">email</i>
                          <input
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            value={email}
                            onChange={e => this._handleChange(e)}
                          />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="input-icon">
                          <i class="material-icons">phone</i>
                          <input
                            type="tel"
                            name="phone"
                            placeholder="Mobile Number"
                            value={phone}
                            onChange={e => this._handleChange(e)}
                          />
                      </div>
                    </div>

                    <div class="col-md-12 m-0">
                    <span></span>
                    <hr class="short" />
                    </div>

                    { (shop.shipping_enabled && shop.shipping_methods === "delivery_and_collection")  &&
                      <>
                        <div class="col-md-12">
                          <div class="form-group">
                            <div class="input-icon">
                              <i class="material-icons">
                                airplanemode_active
                              </i>
                              <select
                                value={shipping_method}
                                name="shipping_method"
                                class="shipping-method-select"
                                onChange={e => this._handleChange(e)}
                                style={{
                                  color: shipping_method ? 'black' : '#a7a6a6',
                                  fontSize: 16
                                }}
                              >
                                { shipping_method == null &&
                                  <option value="null">Delivery Method</option>
                                }
                                { shop.shipping_methods.indexOf("delivery") > -1 &&
                                  <option value="delivery">Send To My Address</option>
                                }
                                { shop.shipping_methods.indexOf("collection") > -1 &&
                                  <option value="collection">Click And Collect</option>
                                }
                              </select>
                              { shipping_method === "collection" &&
                                  <span><b>Collection Point:</b>{` ${shop.location.raw}`}</span>
                              }
                            </div>
                          </div>
                        </div>
                      </>
                    }

                    { stripePromise &&
                      <div class="col-md-12">
                        <LocationInput
                          location={location}
                          showIcon
                          allowManualInput={true}
                          onUpdated={returnedLocation => {
                            let updatedLocation = {
                              ...location,
                              ...returnedLocation
                            }
                            this.setState({
                              error: null,
                              location: updatedLocation
                            })
                          }}/>
                      </div>
                    }

                    <div class="col-md-12 m-0">
                      <span></span>
                      <hr class="short" />
                    </div>

                    <div class="col-md-12 m-0">
                      <PaymentDetails
                        shop={shop}
                        amount={total}
                        website={this.props.website}
                        ref={this.paymentDetails}
                        onAsyncPaymentSuccess={() => {
                          this._onSale({
                            amount: total,
                            data: cart.items,
                            shipping_price: shippingPrice,
                            currency: shop.currency,
                            voucher: cart.coupon
                          })
                        }}
                        onAsyncPaymentFailed={() => {
                          Notify.error("Payment Failed, please try again.")
                        }}
                      />
                    </div>

                    <div class="col-md-12">
                      <a
                        class="btn btn--primary w-100"
                        onClick={e => {
                          e.preventDefault()
                          this._handleCheckout()
                        }}
                      >
                        <span class="btn__text">
                          Purchase  { Currency.format(total, shop.currency) }
                          { (shippingPrice === 0 && shipping_method === "delivery") &&
                            <span class="label product-sale-price-darken">
                              <span>FREE SHIPPING</span>
                            </span>
                          }
                        </span>
                      </a>
                    </div>

                </div>

            </form>

        </div>
      </>
    )
  }

  _renderMeta(){
    let {
      shop
    } = this.state

    if(shop == null){
       return null
    }

    let website = shop.website
    let favIconUrl = website.favicon ? website.favicon.original : "/favicon.png"

    return (
      <Helmet>
          <title>{`Checkout | ${website.name}`}</title>
          <meta name="description" content={ shop.description || website.descrption } />
          <meta name="keywords" content={ shop.keywords || website.keywords } />
          <link rel="icon" href={ favIconUrl }/>
      </Helmet>
    )
  }

  render(){
    let {
      loading,
      checkingOut,
    } = this.state

    return (
      <div class="main-container">
          { this._renderMeta() }
          <section class="cart-customer-details cart-checkout m-0">
              <Div
                className="container"
                disabled={checkingOut}
                spinner={true}
                loadingText={() =>  (
                  <div className="text-center">
                    Processing...
                    <br/>
                    <span style={{ fontSize: 20 }}>(Please don't refresh your page)</span>
                  </div>
                )}
              >

                  <div class="row justify-content-around">

                    { this._renderContent() }

                  </div>

              </Div>
          </section>

      </div>
    )
  }
}
